import {useState} from "react";
import {
    Box,
    Button,
    TextField,
    useMediaQuery,
    Typography,
    useTheme, Select, MenuItem,
} from "@mui/material";
import {Formik} from "formik";
import * as yup from "yup";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {setLogin} from "state";

const registerSchema = yup.object().shape({
    firstName: yup.string().required("required"),
    lastName: yup.string().required("required"),
    email: yup.string().email("invalid email").required("required"),
    password: yup.string().required("required"),
    location: yup.string().required("required"),
    city: yup.string().required("required"),
    country: yup.string().required("required"),
    province: yup.string().required("required"),
    postalCode: yup.string().required("required"),
    phone: yup.string().required("required"),
    occupation: yup.string().required("required"),
    code: yup.string().required("required"),
});

const loginSchema = yup.object().shape({
    email: yup.string().email("invalid email").required("required"),
    password: yup.string().required("required"),
});

const initialValuesRegister = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    location: "",
    city: "",
    country: "",
    province: "",
    postalCode: "",
    phone: "",
    occupation: "",
    code: "",
};

const initialValuesLogin = {
    email: "",
    password: "",
};

// TODO: verification code checker

const Form = () => {
    const [pageType, setPageType] = useState("login");
    const [countryCode, setCountryCode] = useState("+1");
    const [step, setStep] = useState(0);
     //const [isVerificationCodeError, setIsVerificationCodeError] = useState(false);
    const {palette} = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const isLogin = pageType === "login";
    const isRegister = pageType === "register";


    const handleStepChange = (values) => {
        if (step === 1)
            sendVerificationCode(values).then(r => r);
        setStep(step + 1)
    }


    const handleCountryCodeChange = (event) => {
        setCountryCode(event.target.value);
    };

    const addCountryCode = (value) => {
        return countryCode + value;
    };

    const sendVerificationCode = async (values) => {
        await fetch('https://fora-pay.herokuapp.com/verify/phone', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                phone: addCountryCode(values.phone),
            })
        })
    }

    const checkVerificationCode = async (values) => {
        try {
            const response = await fetch('https://fora-pay.herokuapp.com/verify/code', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    phone: addCountryCode(values.phone),
                    code: values.code,
                })
            });

            const data = await response.json();
            return data.status;
        } catch (error) {
            console.error(error);
            return false;
        }
    };


    const register = async (values, onSubmitProps) => {
        try {
            // this allows us to send form info with image
            const formData = new FormData();
            for (let value in values) {
                formData.append(value, values[value]);
            }

            const savedUserResponse = await fetch(
                "https://fora-pay.herokuapp.com/auth/register",
                {
                    method: "POST",
                    body: formData,
                }
            );
            const savedUser = await savedUserResponse.json();
            onSubmitProps.resetForm();

            if (savedUser) {
                setPageType("login");
                setStep(0);
            }

        } catch (error) {
            console.log(error);
        }
    };

    const login = async (values, onSubmitProps) => {
        const loggedInResponse = await fetch("https://fora-pay.herokuapp.com/auth/login", {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(values),
        });
        const loggedIn = await loggedInResponse.json();
        onSubmitProps.resetForm();
        if (loggedIn) {
            dispatch(
                setLogin({
                    user: loggedIn.user,
                    token: loggedIn.token,
                })
            );
            navigate(`/profile/${loggedIn.user._id}`);
        }
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        if (isLogin) await login(values, onSubmitProps);
        if (isRegister) {
            if (await checkVerificationCode(values) === 'approved')
                await register(values, onSubmitProps);
            else {
                values.code = '';
            }
        }
    };

    return (
        <>
            {step === 0 && isRegister && (
                <>
                    <Typography fontWeight="500" variant="h2" sx={{mb: "1.5rem"}}>
                        Sign Up for Forapay
                    </Typography>
                    <Typography fontWeight="200" variant="h6" sx={{mb: "1.5rem"}}>
                        Set up your login credentials to securely manage your digital assets and fiat currencies in one
                        place.
                    </Typography>
                </>
            )}
            {step === 1 && isRegister && (
                <>
                    <Typography fontWeight="500" variant="h2" sx={{mb: "1.5rem"}}>
                        Tell Us About Yourself
                    </Typography>
                    <Typography fontWeight="200" variant="h6" sx={{mb: "1.5rem"}}>
                        We need some basic information to get you started with your crypto bank account.
                    </Typography>
                </>
            )}
            {step === 2 && isRegister && (
                <>
                    <Typography fontWeight="500" variant="h2" sx={{mb: "1.5rem"}}>
                        What Do You Do?
                    </Typography>
                    <Typography fontWeight="200" variant="h6" sx={{mb: "1.5rem"}}>
                        Help us understand your occupation so we can tailor our services to your needs.
                    </Typography>
                </>
            )}
            {step === 3 && isRegister && (
                <>
                    <Typography fontWeight="500" variant="h2" sx={{mb: "1.5rem"}}>
                        Verify Phone Number
                    </Typography>
                    <Typography fontWeight="200" variant="h6" sx={{mb: "1.5rem"}}>
                        Please input the code received via SMS to verify your identity.
                    </Typography>
                </>
            )}
            {isLogin && (
                <>
                    <Typography display="inline-block" fontWeight="500" variant="h2" sx={{mb: "1.5rem"}}>
                        Welcome to FORAPAY
                    </Typography>
                    <Typography fontWeight="200" variant="h6" sx={{mb: "1.5rem"}}>
                        Your crypto solution for secure and innovative financial services.
                    </Typography>
                </>
            )}

            <Formik
                onSubmit={handleFormSubmit}
                initialValues={isLogin ? initialValuesLogin : initialValuesRegister}
                validationSchema={isLogin ? loginSchema : registerSchema}

            >
                {({
                      values,
                      errors,
                      touched,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      resetForm,
                  }) => (
                    <form onSubmit={handleSubmit}>
                        <Box
                            display="grid"
                            gap="30px"
                            gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                            m="0 auto"
                            sx={{
                                "& > div": {gridColumn: isNonMobile ? undefined : "span 4"}
                            }}
                        >
                            {isRegister && (
                                <>
                                    {/* STEP 1 */}
                                    {step === 0 && (
                                        <>
                                            <TextField
                                                label="Email"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.email}
                                                name="email"
                                                error={Boolean(touched.email) && Boolean(errors.email)}
                                                helperText={touched.email && errors.email ? errors.email : ''}
                                                sx={{gridColumn: "span 4"}}
                                            />
                                            <TextField
                                                label="Password"
                                                type="password"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.password}
                                                name="password"
                                                error={Boolean(touched.password) && Boolean(errors.password)}
                                                helperText={touched.password && errors.password}
                                                sx={{gridColumn: "span 4"}}
                                            />
                                        </>

                                    )}
                                    {/* STEP 2 */}
                                    {step === 1 && (
                                        <>
                                            <TextField
                                                label="First Name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.firstName}
                                                name="firstName"
                                                error={
                                                    Boolean(touched.firstName) && Boolean(errors.firstName)
                                                }
                                                helperText={touched.firstName && errors.firstName}
                                                sx={{gridColumn: "span 2"}}
                                            />
                                            <TextField
                                                label="Last Name"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.lastName}
                                                name="lastName"
                                                error={Boolean(touched.lastName) && Boolean(errors.lastName)}
                                                helperText={touched.lastName && errors.lastName}
                                                sx={{gridColumn: "span 2"}}
                                            />
                                            <TextField
                                                label="Address"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.location}
                                                name="location"
                                                error={Boolean(touched.location) && Boolean(errors.location)}
                                                helperText={touched.location && errors.location}
                                                sx={{gridColumn: "span 4"}}
                                            />
                                            <TextField
                                                label="City"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.city}
                                                name="city"
                                                error={Boolean(touched.city) && Boolean(errors.city)}
                                                helperText={touched.city && errors.city}
                                                sx={{gridColumn: "span 2"}}
                                            />
                                            <TextField
                                                label="Country"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.country}
                                                name="country"
                                                error={Boolean(touched.country) && Boolean(errors.country)}
                                                helperText={touched.country && errors.country}
                                                sx={{gridColumn: "span 2"}}
                                            />
                                            <TextField
                                                label="Province"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.province}
                                                name="province"
                                                error={Boolean(touched.province) && Boolean(errors.province)}
                                                helperText={touched.province && errors.province}
                                                sx={{gridColumn: "span 2"}}
                                            />
                                            <TextField
                                                label="Postal code"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.postalCode}
                                                name="postalCode"
                                                error={Boolean(touched.postalCode) && Boolean(errors.postalCode)}
                                                helperText={touched.postalCode && errors.postalCode}
                                                sx={{gridColumn: "span 2"}}
                                            />
                                            <Select
                                                value={countryCode}
                                                onChange={handleCountryCodeChange}
                                                sx={{gridColumn: "span 1 !important"}}
                                            >
                                                <MenuItem value="+1">+1</MenuItem>
                                                <MenuItem value="+44">+44</MenuItem>
                                                <MenuItem value="+61">+61</MenuItem>
                                                {/* Add more country codes here */}
                                            </Select>
                                            <TextField
                                                label="Phone"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.phone}
                                                name="phone"
                                                error={Boolean(touched.phone) && Boolean(errors.phone)}
                                                helperText={touched.phone && errors.phone ? errors.phone : ""}
                                                sx={{gridColumn: "span 3 !important"}}
                                            />
                                        </>
                                    )}
                                    {/* STEP 3 */}
                                    {step === 2 && (
                                        <TextField
                                            label="Occupation"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.occupation}
                                            name="occupation"
                                            error={Boolean(touched.occupation) && Boolean(errors.occupation)}
                                            helperText={touched.occupation && errors.occupation}
                                            sx={{gridColumn: "span 4"}}
                                        />
                                    )}
                                    {/* STEP 4 */}
                                    {step === 3 && (
                                        <>
                                            <TextField
                                                label="Code"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.code}
                                                name="code"
                                                error={Boolean(touched.code) && Boolean(errors.code)}
                                                helperText={touched.code && errors.code}
                                                sx={{gridColumn: "span 4"}}
                                            />
                                        </>
                                    )}
                                </>
                            )}
                            {isLogin && (
                                <>
                                    <TextField
                                        label="Email"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.email}
                                        name="email"
                                        error={Boolean(touched.email) && Boolean(errors.email)}
                                        helperText={touched.email && errors.email}
                                        sx={{gridColumn: "span 4"}}
                                    />
                                    <TextField
                                        label="Password"
                                        type="password"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.password}
                                        name="password"
                                        error={Boolean(touched.password) && Boolean(errors.password)}
                                        helperText={touched.password && errors.password}
                                        sx={{gridColumn: "span 4"}}
                                    />
                                </>
                            )}
                        </Box>


                        {/* BUTTONS */}
                        <Box>
                            {isLogin || step >= 3 ? (
                                <>
                                    <Button
                                        type="submit"
                                        sx={{
                                            m: "2rem 0",
                                            p: "1rem 4rem",
                                            borderRadius: "30px",
                                            backgroundColor: palette.primary.main,
                                            color: palette.background.alt,
                                            "&:hover": {color: palette.primary.main},
                                        }}
                                    >
                                        LOGIN
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        type="button"
                                        sx={{
                                            m: "2rem 0",
                                            p: "1rem 4rem",
                                            borderRadius: "30px",
                                            backgroundColor: palette.primary.main,
                                            color: palette.background.alt,
                                            "&:hover": {color: palette.primary.main},
                                        }}
                                        onClick={()=> handleStepChange(values)}
                                    >
                                        Next
                                    </Button>
                                </>
                            )}
                            <Typography
                                onClick={() => {
                                    setPageType(isLogin ? "register" : "login");
                                    setStep(0);
                                    resetForm();
                                }}
                                sx={{
                                    textDecoration: "underline",
                                    color: palette.primary.main,
                                    "&:hover": {
                                        cursor: "pointer",
                                        color: palette.primary.light,
                                    },
                                }}
                            >
                                {isLogin
                                    ? "Don't have an account? Sign Up here."
                                    : "Already have an account? Login here."}
                            </Typography>

                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

export default Form;
