import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    mode: "light",
    user: null,
    token: null,
    posts: [],
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setMode: (state) => {
            state.mode = state.mode === "light" ? "dark" : "light";
        },
        setLogin: (state, { payload: { user, token } }) => {
            state.user = user;
            state.token = token;
        },
        setLogout: (state) => {
            state.user = null;
            state.token = null;
        },
    },
});

export const {
    setMode,
    setLogin,
    setLogout,
} = authSlice.actions;

export default authSlice.reducer;
