import styles from "./Benefit.module.css";
import React from "react";

const Benefit = (props) => {
    return (
            <div className={styles.meta}>
                <div className={styles.pic}>
                    <img src={props.img} alt="Icon"/>
                </div>
                <h3 className={styles.heading}>
                    {props.title}
                </h3>
                <div className={styles.desc}>
                    {props.text}
                </div>
            </div>
    );
}

export default Benefit;