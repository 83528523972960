import styles from "./promo.module.css";
import Button from "../Button/Button";
import state from "../../projectData/state";

let props = state.promo

const Promo = () => {
    return(
        <div className={styles.promo}>
            <div className={styles.promo_content}>
                <h1 className={styles.title}>
                    {props.title}
                </h1>
                <div className={styles.promo_text}>
                    {props.text}
                </div>
                <Button />
            </div>
            <div className={styles.promo_picture}>
                <img src={props.img} alt={'promo'}
                       width={1000}
                       height={600}
                />
            </div>
        </div>
    );
}


export default Promo;