import styles from "./Footer.module.css";
import policy from "../../projectData/Fora PayIO_Website Privacy Policy.pdf"

const Footer = () => {
  return (
    <footer className={styles.footerTeg}>
      <div className={styles.footer1}>
        <div className={styles.footer_content}>
          <div className={styles.logoFooter}>
            <a href="/">
              <img
                src={"/Images/logo.svg"}
                alt="Logo"
                width={145}
                height={23}
              />
            </a>
          </div>
          <div className={styles.menu}>
            <div>
              <address className={styles.contactInfo}>
                <div className={styles.title}>Toronto</div>
                <div className={styles.text}>
                  <div>
                    <div>1110 Finch Ave W, Suite 406</div>
                    <div>North York, ON M3L 2T2</div>
                  </div>
                  <div>
                    <div>
                      <a href="mailto:info@forapay.ca">info@forapay.ca</a>
                    </div>
                    <div>
                      <a href="tel:+1 (647) 824-3697">+1 (647) 824-3697</a>
                    </div>
                  </div>
                </div>
              </address>
            </div>
            <div className={styles.following}>
              <div className={styles.title}>Follow Us</div>
              <div className={styles.socialMedia}>
                <div>
                  <a href="#">Facebook</a>
                </div>
                <div>
                  <a href="#">Twitter</a>
                </div>
                <div>
                  <a href="#">Linkedin</a>
                </div>
                <div>
                  <a href="#">Youtube</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footer2}>
        <div className={styles.footer_content2}>
          <div className={styles.policy}>
            <div>
              <nav className={styles.nav_footer}>
                <a href={policy} without rel="noopener noreferrer" target="_blank">Privacy</a>
                <a href="/">Security</a>
                <a href="/">Legal</a>
                <a href="/">Codes and commitments</a>
                <a href="/">Resolving your concerns</a>
                <a href="/">Accessibility</a>
                <a href="/">Forms</a>
              </nav>
            </div>
            <div className={styles.ending}>
              <div>
                Fora Pay Inc is authorised by the Financial Transactions and
                Reports Analysis Centre of Canada (FINTRAC) under the Electronic
                Payment Regulations SOR/98-129, company registration number
                M21411713 issued for Money Service business (MSB) institutions
                in Canada.
              </div>

              <div>© ForaPay - Fora Pay Inc 2021</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
