import styles from './ForaMap.module.css'
import MapElement from "./MapElement";
import state from "../../projectData/state";

let props = state.map

// TODO: make a griding for the map

const ForaMap = () => {
    return(
        <div className={styles.container}>
            <div className={styles.head}>
                <div className={styles.title}>{props.title}</div>
                <div className={styles.subTitle}>
                    {props.text}
                </div>
            </div>
            <div className={styles.mapContainer}>
                <MapElement
                    title={props.phases.phase1.title}
                    text={props.phases.phase1.text}
                    status={props.phases.phase1.status}
                />
                <MapElement
                    title={props.phases.phase2.title}
                    text={props.phases.phase2.text}
                    status={props.phases.phase2.status}
                />
                <MapElement
                    title={props.phases.phase3.title}
                    text={props.phases.phase3.text}
                    status={props.phases.phase3.status}
                />
                <MapElement
                    title={props.phases.phase4.title}
                    text={props.phases.phase4.text}
                    status={props.phases.phase4.status}
                    style={{ color: 'red' }}
                />
            </div>
        </div>
    );
}

export default ForaMap;
