import NavLinks from "./NavLinks";
import styles from './NavBar.module.css'
import {CgCloseO, CgMenuRound} from "react-icons/cg";
import {useState} from "react";
import React from "react";

const MobileNavigation = () => {

    const [open, setOpen] = useState(false);

    const hamburgerIcon = <CgMenuRound className={styles.Hamburger}
                                       size={40} color={'#494949'}
                                       onClick={() => setOpen(!open)}/>

    const closeIcon = <CgCloseO className={styles.Hamburger}
                                size={40} color={'#494949'}
                                onClick={() => setOpen(!open)}/>

    const closeMobileMenu = () => setOpen(false);

    return (
        <nav className={styles.MobileNavigation}>
            <div className={styles.MobileLogo}>
                <a href='/'>
                        <img src="/Images/logo.svg" alt="logo" width={147} height={23}/>
                </a>
            </div>
            {open ? closeIcon : hamburgerIcon}
            {open && <NavLinks isMobile={true} closeMobileMenu={closeMobileMenu}/>}
        </nav>
    );
}

export default MobileNavigation;