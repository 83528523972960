import PropTypes from 'prop-types'
import styles from './calculater.module.css'

const CurrencyInput = (props) => {
    return (
        <div>
            <laibel className={styles.liable}>{props.lable}</laibel>
            <div className={styles.group}>
                <input className={styles.input} type={'text'} value={props.amount} onChange={event => props.onAmountChange(event.target.value)}/>
                <select className={styles.select} value={props.currency} onChange={event => props.onCurrencyChange(event.target.value)}>
                    {props.currencies.map((currency => (
                        <option className={styles.options} value={currency}>{currency}</option>
                    )))}
                </select>
            </div>
        </div>
    )
}

CurrencyInput.propTypes = {
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    lable: PropTypes.string.isRequired,
    currencies: PropTypes.array,
    onAmountChange: PropTypes.func,
    onCurrencyChange: PropTypes.func,
}

export default CurrencyInput;