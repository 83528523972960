import styles from "./calculater.module.css"
import Benefit from "../Benefit";
import React from "react";
import {useState, useEffect} from "react";
import CurrencyInput from "./CurrencyInput";
import axios from "axios";
import DigitButton from "./DigitButton";
import state from "../../projectData/state";

let props = state.calculator


const Calculator = () => {

    const [amount1, setAmount1] = useState(1)
    const [amount2, setAmount2] = useState(1)
    const [currency1, setCurrency1] = useState('BTC')
    const [currency2, setCurrency2] = useState('ETH')
    const [rates, setRates] = useState([])

    useEffect(() => {
        axios.get('https://api.coingecko.com/api/v3/exchange_rates')
            .then(response => {
                setRates(response.data.rates)
            })
    }, [])

    useEffect(() => {
        if (!!rates) {
            function init() {
                handleAmount1Change(1);
            }

            init();
        }
    }, [rates]);

    let newRates = Object.values(rates).reduce((newRatesObject, el) => {
        return {
            ...newRatesObject,
            [el.unit]: el.value,
        }
    }, {});

    const format = (number) => {
        return number.toFixed(4)
    }

    const handleAmount1Change = (amount1) => {
        setAmount2(format(amount1 * newRates[currency2] / newRates[currency1]))
        setAmount1(amount1);
    }

    const handleCurrency1Change = (currency1) => {
        setAmount2(format(amount1 * newRates[currency2] / newRates[currency1]))
        setCurrency1(currency1);
    }

    const handleAmount2Change = (amount2) => {
        setAmount1(format(amount2 * newRates[currency1] / newRates[currency2]))
        setAmount2(amount2);
    }

    const handleCurrency2Change = (currency2) => {
        setAmount1(format(amount2 * newRates[currency1] / newRates[currency2]))
        setCurrency2(currency2);
    }

    const handleDigitChange = (amount1) => {
        console.log(amount1)
        setAmount1(amount1)
        setAmount2(format(amount1 * newRates[currency2] / newRates[currency1]))
    }


    return (
        <div className={styles.container}>
            <div className={styles.icon}>{props.title.icon}</div>
            <div className={styles.title}>
                <span className={styles.titleBlue}>{props.title.title1}</span>
                {props.title.title21}
            </div>
            <div className={styles.subTitle}>
                {props.title.text}
            </div>
            <div className={styles.benefits}>
                <div className={styles.benefits1}>
                    <div className={styles.benefits11}>
                        <Benefit
                            title={props.benefits.buy.title}
                            text={props.benefits.buy.text}
                            img={props.benefits.buy.img}
                        />
                        <img className={styles.benefitBridge} src={'/Images/bridge.svg'} alt={'bridge'} height={65} width={126}/>
                    </div>
                    <div className={styles.benefits11}>
                        <Benefit
                            title={props.benefits.transfer.title}
                            text={props.benefits.transfer.text}
                            img={props.benefits.transfer.img}
                        />
                        <img className={styles.benefitBridge} src={'/Images/bridge.svg'} alt={'bridge'} height={65} width={126}/>
                    </div>
                </div>
                <div className={styles.benefits1}>
                    <div className={styles.benefits11}>
                        <img className={styles.benefitBridge} src={'/Images/bridge.svg'} alt={'bridge'} height={65} width={126}/>
                        <Benefit
                            title={props.benefits.trade.title}
                            text={props.benefits.trade.text}
                            img={props.benefits.trade.img}
                        />
                    </div>
                    <div className={styles.benefits11}>
                        <img className={styles.benefitBridge} src={'/Images/bridge.svg'} alt={'bridge'} height={65} width={126}/>
                        <Benefit
                            title={props.benefits.withdrawal.title}
                            text={props.benefits.withdrawal.text}
                            img={props.benefits.withdrawal.img}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.calculator}>
                <div className={styles.calculatorBackG}>
                    <img src={'/Images/CalculaterBackground.png'} alt={'Calculator'} width="100%" height="auto"/>
                </div>
                <div className={styles.inputContainer}>
                    <CurrencyInput
                        onAmountChange={handleAmount1Change}
                        onCurrencyChange={handleCurrency1Change}
                        currencies={Object.keys(newRates)}
                        amount={amount1}
                        currency={currency1.toUpperCase()}
                        lable={'You Pay'}
                    />
                    <button
                        onClick={
                            () => {
                                setAmount1(amount2)
                                setAmount2(amount1)
                                setCurrency1(currency2)
                                setCurrency2(currency1)
                            }
                        }
                        className={styles.changeButton}>
                        <img src={'/Images/revert.png'} alt={'Change'} width={50} height={50}/>
                    </button>
                    <CurrencyInput
                        onAmountChange={handleAmount2Change}
                        onCurrencyChange={handleCurrency2Change}
                        currencies={Object.keys(newRates)}
                        amount={amount2}
                        currency={currency2.toUpperCase()}
                        lable={'You Get'}
                    />
                </div>
                <div className={styles.numPud}>
                    <DigitButton
                        onAmountChange={handleDigitChange}
                        amount={amount1}
                        digit={'1'}
                        icon={'1'}
                    />
                    <DigitButton
                        onAmountChange={handleDigitChange}
                        amount={amount1}
                        digit={'2'}
                        icon={'2'}
                    />
                    <DigitButton
                        onAmountChange={handleDigitChange}
                        amount={amount1}
                        digit={'3'}
                        icon={'3'}
                    />
                    <DigitButton
                        onAmountChange={handleDigitChange}
                        amount={amount1}
                        digit={'4'}
                        icon={'4'}
                    />
                    <DigitButton
                        onAmountChange={handleDigitChange}
                        amount={amount1}
                        digit={'5'}
                        icon={'5'}
                    />
                    <DigitButton
                        onAmountChange={handleDigitChange}
                        amount={amount1}
                        digit={'6'}
                        icon={'6'}
                    />
                    <DigitButton
                        onAmountChange={handleDigitChange}
                        amount={amount1}
                        digit={'7'}
                        icon={'7'}
                    />
                    <DigitButton
                        onAmountChange={handleDigitChange}
                        amount={amount1}
                        digit={'8'}
                        icon={'8'}
                    />
                    <DigitButton
                        onAmountChange={handleDigitChange}
                        amount={amount1}
                        digit={'9'}
                        icon={'9'}
                    />
                    <DigitButton
                        onAmountChange={handleDigitChange}
                        amount={amount1}
                        digit={'.'}
                        icon={'.'}
                    />
                    <DigitButton
                        onAmountChange={handleDigitChange}
                        amount={amount1}
                        digit={'0'}
                        icon={'0'}
                    />
                    <DigitButton
                        onAmountChange={handleDigitChange}
                        amount={amount1}
                        icon={ <img style={{ pointerEvents: 'none' }} src={'/Images/errase.svg'} alt={'del'} width="30%" height="auto"/>}
                    />
                </div>
            </div>
        </div>
    );
}


export default Calculator;