import NavBar from "../../components/Header/NavBar";
import Promo from "../../components/Promo/Promo";
import Calculator from "../../components/Calculater/Calculator";
import ForaMap from "../../components/ForaMap/ForaMap";
import PromoEnding from "../../components/PromoEnding/PromoEnding";
import styles from '../../styles/Home.module.css';
import state from "../../projectData/state";
import Slider from "../../components/Slider/Slider";
import Footer from "../../components/Footer";
import Carousel from "components/Carusel/Carousel";

const props = state;

const HomePage = () => {
    return (
        <div className={styles.container}>
            <div>
                <title>ForaPay</title>
                <meta name="description" content="Crypto banking"/>
                <base href="/"/>
                <link rel="shortcut icon" href="/Images/favicon.ico"/>
                <link rel="apple-touch-icon" sizes="180x180" href="/Images/apple-touch-icon.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/Images/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/Images/favicon-16x16.png"/>
            </div>
            <NavBar props={state}/>
            <main className={styles.main}>
                <Promo props={props.promo}/>
                <div className={styles.idWrapper} id={'aboutBank'}>
                    <Calculator/>
                </div>
                <div className={styles.idWrapper} id={'products'}>
                    <Carousel />
                </div>
                <div className={styles.idWrapper} id={'roadMap'}>
                    <ForaMap/>
                </div>
                <div className={styles.idWrapper} id={'contactForm'}>
                    <PromoEnding/>
                </div>
            </main>
            <Footer />
        </div>
    )
}

export default HomePage;