import styles from './PromoEnding.module.css'
import React, {useState, useRef} from "react";
import Popup from '../PopUp/PopUp';
import emailjs from '@emailjs/browser';
import state from "../../projectData/state";

let props = state.contactForm

const Result = () => {
    return (
        <div className={styles.result}>Your message has been successfully sent. We will contact you soon!</div>
    );
}


const PromoEnding = () => {

    const form = useRef();
    const [result, showResult] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_8h4xqxe', 'template_pukz8wg', form.current, '0-ir_7k8VecDU9f_A')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
        showResult(true);
    };

    const [isOpen, setIsOpen] = useState(false);

    const togglePopup = () => {
        setIsOpen(!isOpen);
        showResult(false);
    }

    return (
        <div className={styles.container}>
            {isOpen && <Popup
                content={
                    <div className={styles.containerPopUp}>
                        <form className={styles.popUpContainer} ref={form} onSubmit={sendEmail}>
                            <div className={styles.formArea}>
                                <h1 className={styles.popUpTitle}>
                                    {props.popUp.title}
                                </h1>
                                <p className={styles.popUpSubtitle}>
                                    {props.popUp.text}
                                </p>
                                <input className={styles.textAreasItem}
                                       placeholder={'Phone'}
                                       type="phone"
                                       name="user_phone" required/>

                                <input type="submit" value="→" className={styles.submit}/>
                            </div>
                            <div className={styles.row}>{
                                result ? <Result/> : null
                            }
                            </div>
                        </form>
                        <div className={styles.popUpImg}>
                            <img src={props.popUp.img} alt={'Phone app'} />
                        </div>
                    </div>
                }
                handleClose={togglePopup}
            />}
            <div className={styles.content}>
                <div className={styles.title}>
                    {props.title}
                </div>
                <div className={styles.text}>
                    {props.text}
                </div>
                <div className={styles.button}>
                    <input
                        className={styles.buttonInput}
                        type="button"
                        value={`Get started`}
                        onClick={togglePopup}/>

                    <div>
                        <img src={'/Images/ArrowBlue.svg'} alt={'Arrow'}/>
                    </div>
                </div>
            </div>
            <div className={styles.backgroungImge} width="100%" height="50%">
                <img src={'/Images/PromoEndingCryptoAbstraction.png'} alt={'Crypto img'} width="100%" >
                </img>
            </div>
        </div>
    );
}


export default PromoEnding;