import styles from './ForaMap.module.css'

const MapElement = (props) => {
    return(
        <div className={props.status !== 'Ongoing' ? styles.mapElementContainerCompleted : styles.mapElementContainerOnGoing}>
            <div className={props.status !== 'Ongoing'? styles.statusCompleted : styles.statusOnGoing}>
                {props.status}
            </div>
            <div className={styles.phase}>
                <img src={props.status !== 'Ongoing' ? '/Images/statusComplete.svg' : '/Images/onGoingStatus.svg'} alt={'Status icon'} width={40} height={40}/>
                <div
                    className={props.status !== 'Ongoing' ? styles.phaseTitleCompleted : styles.phaseTitleOnGoing}>
                    {props.title}
                </div>
            </div>
            <div className={styles.text}>
                {props.text}
            </div>
        </div>
    );
}

export default MapElement;