import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { Box, Button, Typography, switchClasses } from "@mui/material";
import state from '../../projectData/state'
import Slide from './Slide';

let props = state.slider

const Carousel = () => {
    const [ isHidden, setIsHidden ] = useState(false);
    const [ isActiveBar, setIsActiveBar ] = useState(0);
    const [ isActivePage, setIsActivePage] = useState(0);
    const isNonMobile = useMediaQuery("(min-width:600px)");
    
    const handleClick = () => {
        setIsHidden(!isHidden)
    }
    const q = 0
    const a = 26
    const b = 50
    const c = 73

    const handleActive = (a) => {
     switch (a) {
       case 0:
        setIsHidden(false);
         setIsActiveBar(0);
         setIsActivePage(0);
         break;
       case 1:
        setIsHidden(false);
         setIsActiveBar(26);
         setIsActivePage(-100);
         break;
       case 2:
        setIsHidden(false);
         setIsActiveBar(50);
         setIsActivePage(-200);
         break;
       case 3:
        setIsHidden(false);
         setIsActiveBar(72);
         setIsActivePage(-300);
         break;
       default:
        setIsHidden(false);
         setIsActiveBar(0);
         setIsActivePage(0);
     }
    }

    return (
      <Box
        bgcolor="#F9FBFD"
        borderRadius="0 0 700px 700px / 23.5px"
        m="100px 0"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          maxWidth="1200px"
          margin="0 auto"
        >
          {/* Title & Text */}
          <Typography
            sx={{
              maxWidth: "749px",

              fontStyle: "normal",
              fontWight: "400",
              fontSize: "71px",
              textAlign: "center",

              color: "#393737",
              mb: "10px",
              mt: "50px",
            }}
          >
            {props.title}
          </Typography>
          <Typography
            sx={{
              maxWidth: "592px",

              /* h3 */

              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "20px",
              /* or 31px */

              textAlign: "center",

              color: "#70748C",

              marginBottom: "80px",
            }}
          >
            {props.subtitle}
          </Typography>
          {/* Carousel */}
          <Box width="100%" display="flex" flexDirection="column">
            {/* Status Bar */}
            <Box width="90%" m="0 auto">
              <Box
                display="flex"
                justifyContent="space-around"
                
                sx={{
                  fontStyle: "normal",
                  fontWeight: "400",
                  fontSize: "18px",
                  color: "#4759FD",
                  marginBottom: "10px",
                  cursor: "pointer",
                  
                 
                  '& < div': { flex: '1', padding: '10px',},
                }}
              >
                <Typography onClick={() => handleActive(0)}>Exchange</Typography>
                <Typography onClick={() => handleActive(1)}>Stake</Typography>
                <Typography onClick={() => handleActive(2)}>Earn</Typography>
                <Typography onClick={() => handleActive(3)}>Spend</Typography>
              </Box>
              <Box position="relative" w="100%" height="5px">
                <Box
                  position="absolute"
                  height="5px"
                  bgcolor="#CDD3FD;"
                  width="100%"
                ></Box>
                <Box
                  position="absolute"
                  height="7px"
                  top="-1px"
                  bgcolor="#4759FD;"
                  width="30%"
                  sx={{
                    left: `${isActiveBar}%`,
                    transition: `left ${300}ms ease-in-out`,
                  }}
                ></Box>
              </Box>
            </Box>
            {/* Pages */}
            <Box
              display="-webkit-box"
              overflow="hidden"
              width="100%"
              minHeight="645px"
              height="100%"
              sx={{
                "& > div": {
                  p: "0 20px",
                  width: "100%",
                  display: "flex",
                  flexDirection: `${isNonMobile ? "row" : "column-reverse"}`,
                  justifyContent: "space-around",
                  alignItems: "center",
                  transform: `translateX(${isActivePage}%)`,
                  transition: `transform ${300}ms ease-in-out`,
                },
              }}
            >
              {/* PAGE 1 */}
              <Slide props={props.exchange} />
              {/* PAGE 2 */}
              <Slide props={props.eran} />
              {/* PAGE 3 */}
              <Slide props={props.save} />
              {/* PAGE 4 */}
              <Slide props={props.spend} />
            </Box>
          </Box>
        </Box>
      </Box>
    );
}

export default Carousel;