import styles from "./button.module.css";
import state from "../../projectData/state";

let props = state.promo.button

const Button = () => {
    return(
        <div className={styles.button}>
            <div className={styles.buttonText}>
                {props.buttonText}
            </div>
            <div className={styles.button_arrow}>
                <img src={props.buttonArrowImg} alt={'Arrow'} width={22} height={22}/>
            </div>
        </div>
    );
}


export default Button;