import PropTypes from 'prop-types'
import styles from './calculater.module.css'

const DigitButton = (props) => {
    return (
        <>
            <button
                className={styles.number}
                value={props.digit ? String(props.amount) + props.digit : props.amount}
                onClick ={event => {
                    if (!props.digit){
                        return event.target.value ? props.onAmountChange(event.target.value.slice(0, -1)) : 0}
                    return props.onAmountChange(event.target.value)
                }
            }>
                {props.icon}
            </button>
        </>
    )
}

DigitButton.propTypes = {
    amount: PropTypes.string.isRequired,
    digit: PropTypes.string.isRequired,
    icon: PropTypes.element.isRequired,
    onAmountChange: PropTypes.func,
}

export default DigitButton;