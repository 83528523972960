import { useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { Box, Button, Typography, switchClasses } from "@mui/material";


const Slide = (props) => {
  const [isHidden, setIsHidden] = useState(false);
  const isNonMobile = useMediaQuery("(min-width:600px)");

  const handleClick = () => {
    setIsHidden(!isHidden);
    console.log(props);
  };
  return (
    <>
      {!isHidden ? (
        <Box
          sx={{
            "& > div": { width: `${isNonMobile ? "50%" : "100%"}` },
          }}
        >
          <Box display="flex" flexDirection="column" gap="30px">
            <Typography
              sx={{
                maxWidth: "484px",

                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "40px",

                color: "#393737",
              }}
            >
              {props.props.title}
            </Typography>
            <Typography
              sx={{
                maxWidth: "500px",

                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "16px",

                color: "#70748C",
              }}
            >
              {props.props.text}
            </Typography>
            <Box>
              <Button onClick={handleClick}>Learn more</Button>
            </Box>
          </Box>
          <Box
        borderRadius="30px"
        overflow="hidden"
        >
            <img
              src={props.props.img}
              alt={"Crypto exchange"}
              width="100%"
              height="auto"
            />
          </Box>
        </Box>
      ) : (
        <Box
          flexDirection="column !important"
          justifyContent="center !important"
          gap="30px"
          sx={{}}
        >
          <Typography
            sx={{
              maxWidth: "484px",

              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "40px",

              color: "#393737",
            }}
          >
            {props.props.titleBack}
          </Typography>
          <Typography
            sx={{
              maxWidth: "500px",

              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "16px",

              color: "#70748C",
            }}
          >
            {props.props.textBack}
          </Typography>
          <Button onClick={handleClick}>Back</Button>
        </Box>
      )}
    </>
  );
}
    
export default Slide;