const state = {
    promo: {
      title: 'Crypto finances for your matters',
      text: 'ForaPay is a licensed digital service providing a simple, fast and secure platform to manage your digital assets and fiat currencies in one place.',
      button: {
        buttonText: 'About the platform',
        buttonArrowImg: '/Images/Arrow.svg',
      },
      img: '/Images/promoImg.png',
    },
    calculator: {
      title: {
        icon: 'Freedom Finance',
        title1: 'One wallet ',
        title21: 'for all\nyour matters',
        text: 'Fora Pay - multicurrency wallet powered by in-demand financial solutions that provides unlimited financial services of the future. Our app has all the tools to buy, sell, earn, invest and save any of your assets, on any device.',
      },
      benefits: {
        buy: {
          title: 'Buy',
          text: 'Buy over 200 cryptocurrencies in just a few clicks in any way you like',
          img: '/Images/bitcoin-convert.svg',
        },
        transfer: {
          title: 'Transfer',
          text: 'Transfer or pay worldwide to any financial institution, ForaPay wallet, or external wallets',
          img: '/Images/transferRocket.svg',
        },
        trade: {
          title: 'Trade',
          text: 'Exchange any cryptocurrency at any time with minimal fees and at a fixed price',
          img: '/Images/trade.svg',
        },
        withdrawal: {
          title: 'Withdrawal',
          text: 'Cash out your crypto or withdraw it directly to your financial institution in minutes, not days',
          img: '/Images/card.svg',
        },
      }
    },
    slider: {
      title: 'Treat your money right',
      subtitle: 'Explore all the ways ForaPay helps you save, spend, earn, borrow, invest, and protect your money-all in one app.',
      exchange: {
        title: 'Exchange your crypto holdings the way you like',
        text: 'ForaPay Wallet allows you to exchange your crypto assets for fiat or other cryptos, and withdraw them to any financial account or external cryptowallet',
        button: 'Learn more',
        img: '/Images/CryptoExchange.png',
        titleBack: 'Exchange Flexibility',
        textBack: 'At ForaPay, we understand that every individual has unique preferences when it comes to exchanging their crypto holdings. That\'s why we offer you the freedom to exchange your digital currencies the way you like. Whether you prefer quick and seamless transactions or want to explore different exchange platforms, ForaPay provides you with the flexibility to make the most out of your crypto investments.',
      },
      eran: {
        title: 'A way of earning from the Sataking',
        text: 'ForaPay gives you a wide range of tools for your profit. Stake your coins through the P2P platform.',
        button: 'Learn more',
        img: '/Images/Staking.jpg',
        titleBack: 'Future Earnings',
        textBack: 'Unlock the potential of your crypto assets with ForaPay\'s innovative staking solutions. By participating in our staking program, you can earn passive income from the future. Staking allows you to support and validate transactions on the blockchain network while earning rewards in return. Experience the power of staking and watch your digital currencies grow with ForaPay.',
      },
      save: {
        title: 'Earn profit from saving your digital currencies',
        text: 'ForaPay offers a unique fixed-rate crypto saving option. Save your digital funds without loss',
        button: 'Learn more',
        img: '/Images/Save.png',
        titleBack: 'Profitable Savings',
        textBack: 'At ForaPay, we believe that your digital currencies should work for you. With our platform, you can earn profit by saving your crypto holdings. Through various investment opportunities and strategies, we provide you with a secure and reliable way to grow your wealth. Start maximizing the potential of your digital assets and enjoy the benefits of profitable savings with ForaPay.',
      },
      spend: {
        title: 'Use crypto the way you used to',
        text: 'Order ForaPay Card and spend any cryptocurrency as easy as fiat. Pay for your purchases and favorite services online and by terminal all over the world',
        button: 'Learn more',
        img: '/Images/Spend.png',
        titleBack: 'Crypto Card Convenience',
        textBack: 'Step into the world of seamless crypto transactions with ForaPay\'s crypto card. Our versatile and secure card allows you to use your digital currencies just like you used to with traditional fiat currencies. Whether you\'re shopping online or making purchases at your favorite local stores, our crypto card enables you to spend your money with ease. Embrace the convenience of using crypto in your everyday life with ForaPay.',
      },
    },
    map: {
      title: 'Our Journey',
      text: 'This timeline details our funding and development goals.',
      phases: {
        phase1: {
          title: 'Phase 1',
          text: 'ForaPay was founded in February 2021 in Canada. During this phase, the company was established and the initial steps were taken to build the brand and lay the foundation for future growth.',
          status: 'Completed'
        },
        phase2: {
          title: 'Phase 2',
          text: 'In May 2021, ForaPay obtained its Financial Transactions and Reports Analysis Centre of Canada (FINTRAC) license. This was a key step in the company\'s development as it enabled ForaPay to offer its services to a wider range of customers.',
          status: 'Completed'
        },
        phase3: {
          title: 'Phase 3',
          text: 'In Q1 2023, ForaPay launched its product to the market. This phase marked the official launch of the company\'s services and marked the beginning of its efforts to acquire customers and generate revenue.',
          status: 'Ongoing'
        },
        phase4: {
          title: 'Phase 4',
          text: 'From 2023 to 2025, ForaPay will focus on expanding its customer base and growing its market share in Canada and Europe. The company will aim to increase its visibility and establish itself as a leading provider of online payment solutions in these regions.',
          status: 'Ongoing'
        },
      },
    },
    contactForm: {
      title: 'Want to try by yourself?',
      text: 'So what are you waiting for? Create an account and start using the most powerful and technologically advanced platform for creating your financial freedom.',
      img: '/Images/PromoEndingCryptoAbstraction.png',
      popUp: {
        title: 'Ready to get started?',
        text: 'By entering your phone number, you’ll allow us to send you an SMS with a link to download the ForaPay app.',
        img: '/Images/PopUpPhone.png',
      }
    },
  }
  
  export default state;
  